.blogs-container {
  display: flex;
  flex-wrap: wrap;
  margin: 20px;
  margin-top: 30px;
  width: fit-content;
}
.blog-card {
  max-width: 380px;
  min-width: 300px;
  flex: 25%;
  /* height: 300px; */
  background-color: #f9f9f9;
  /* justify-content: center; */
  /* align-items: center; */
  display: flex;
  flex-direction: column;
  cursor: pointer;

  margin: 20px;
}
.caption {
  font-size: 18px;
  font-weight: 400;
  margin-left: 20px;
  margin-bottom: 20px;
}
.small-txt {
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-transform: uppercase;
  margin-left: 5px;
}
.linear-div {
  margin-left: 20px;
}
