.contact_hero {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.contact_hero p {
  color: #000;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
}

.contact_hero p span {
  color: #00b0cb;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
}

.contactSection {
  padding: 3rem 2rem;
  width: 95%;
  margin: auto;
}

.contactCard {
  display: flex;
}

.contactCard .card1 {
  padding: 1rem 2rem;
}

.contactCard p {
  color: #4f4f4f;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 400;
  text-transform: lowercase;
  text-align: justify;
}

.contactInfo {
  margin-top: 4rem;
}

.contactInfo img {
  width: 50px;
  height: 50px;
  margin-right: 2rem;
}

.contactInfo .address {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 2rem;
}

.contactForm {
  padding: 1rem 5rem;
  display: flex;
  flex-direction: column;
}

.contactForm h1 {
  margin-bottom: 2rem;
}

.contactForm input,
.contactForm textarea {
  border-radius: 0.3125rem;
  border: 1px solid #00b0cb;
  background: #fff;
  padding: 18px 0px 18px 35px;
  margin-bottom: 2rem;
  box-shadow: none !important;
  outline: none !important;
  color: #00b0cb !important;
  font-size: 1.6rem;
  /* width: 100%; */
}
.contact-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.contactForm button {
  border-radius: 0.3125rem;
  border: 1px solid #00b0cb;
  background-color: #00b0cb;
  color: white;
  padding: 1rem 2.5rem;
}
@media only screen and (max-width: 991px) {
  .contact-wrapper {
    align-items: center;
    justify-content: center;
  }
  .contactForm {
    width: 80%;
    margin-top: 50px;
  }
}
