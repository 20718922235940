.quotaion-container {
  background-color: #f1fdff;
  display: flex;
  margin: 0;
}
.no-shadow-card {
  background-color: #fff;
  border-radius: 10px;
  margin: 15px;
  padding-top: 8px;
  padding-bottom: 8px;
  min-width: 233px;
  max-width: 333px;
  /* padding: 20px; */
}

.shadow-card {
  background-color: #fff;
  border-radius: 10px;
  margin: 15px;
  padding-top: 8px;
  padding-bottom: 8px;
  min-width: 233px;
  box-shadow: 0px 5px 11px 0px rgba(0, 0, 0, 0.2);
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.left-section {
  flex: 30%;
  margin: 0px;
}
.main-section {
  flex: 70%;
  margin: 0px;
  /* background-color: green; */
}
.linear-div {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px;
}

.small-text {
  color: #a3a3a3;
  font-size: 12px;
}

.semibold-text {
  color: black;
  font-size: 16px;
  font-weight: 500px;
}

p {
  margin: 0;
}

.line {
  width: 100%;
  border-bottom: 1px solid #eaeaea;
  padding: 0px;
}

.edit-btn {
  color: #00b0cb;
  font-size: 12px;
  font-weight: 700;
}

.price-div {
  background-color: #00b0cb;
  width: 170px;
  height: 50px;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.price-txt {
  font-size: 20px;
  color: #fff;
  font-weight: 600;
}

.sub-headline {
  color: #455a64;
  font-size: 16px;
  font-weight: 500;
}

.headline {
  font-size: 23px;
  font-weight: 500px;
  margin-bottom: 10px;
}

.input-wrapper {
  display: flex;
  justify-content: space-between;
  margin: 10px;
  flex-wrap: wrap;
}

.button-txt {
  color: #757575;
  font-size: 17px;
  font-weight: 400;
}
/* @media only screen and (max-width: 860px) {
  .quotaion-container {
    flex-direction: column;
  }
  .left-section {
    display: flex;
    flex-wrap: wrap;
  }
} */
