#main-heading {
  margin-top: 12vh;
  text-align: center;
  margin-bottom: 7vh;
}

#main-heading span {
  color: #00b0cb;
}

#about-us {
  display: flex;
  margin-left: 40px;
  /* justify-content: space-between; */
  /* flex-wrap: wrap; */
}

.about-us-cont {
  /* width: 48vw; */
  /* height: 95vh; */

  /* padding-left: 1%; */
  line-height: 1.3;
  /* letter-spacing: 2px; */
}
.about-us-cont span {
  font-size: 30px;
  font-weight: 600;
  letter-spacing: 1px;
}

.about-us-cont p {
  margin-top: 20px;
  list-style: none;
  line-height: 24px;
  color: #4f4f4f;
  font-size: 15px;
}

.about-us-img {
  /* width: 50vw; */
  /* height: 90vh; */
}

.about-us-img img {
  object-fit: cover;
  width: 100%;
}

#how-to {
  /* height: 69vh; */
  background-color: #f1fdff;
}
#how-to span {
  font-size: 30px;
  font-weight: 600;
}
#how-to p {
  color: #4f4f4f;
  font-size: 15px;
  line-height: 1.5;
  align-self: center;
}

.about-main-heading {
  width: 80vw;
  margin-left: 10%;
}

.about-main-heading h1 {
  font-size: 2rem;
  text-align: center;
  font-weight: 600;
  letter-spacing: 1.5;
  font-size: 30px;
  line-height: 125.5%;
  font-weight: 600;
  color: #000;
}

.about-main-heading p {
  font-size: 1.2rem;
  line-height: 24px;
  color: #4f4f4f;
  text-align: center;
  letter-spacing: 1.2px;
}

.how-do-we-container {
  /* margin-top: 10vh; */
  display: flex;
  flex-direction: row;
  /* justify-content: space-evenly; */
}
.helping-ideas-container {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 50px;
}

.helping-ideas {
  /* margin-top: 10vh; */
  /* width: 18vw; */
  /* height: 50vh; */
  max-width: 319px;
  padding: 20px;
}

.helping-img {
  margin-left: 3%;
  width: 139px;
  height: 139px;
  border-radius: 69.5px;
  background-color: #fff3dd;
  display: flex;
  align-items: center;
  justify-content: center;
}

.helping-content {
  /* margin-top: 4%; */
  /* padding: 20px; */
  margin-top: 15px;
}

.helping-content h1 {
  font-size: 24px;
  font-weight: 500;
  text-transform: capitalize;
  font-weight: 600;
  color: #1c1c1c;
}
.helping-content p {
  font-size: 1.4rem;
  line-height: 24px;
  color: #4f4f4f;
  text-align: left;
  /* width: 319px; */
  /* height: 82px; */
}

#about-us-bnft {
  display: flex;
  justify-content: space-between;
  margin-top: 0;
  padding-top: 0px;
  align-items: center;
}

.about-us-cont-bnft {
  /* width: 40vw; */
  /* height: 50vh; */
  /* padding-left: 1%; */
  line-height: 1.3;
  padding: 10px;
  /* margin-top: 10vh; */

  /* letter-spacing: 2px; */
}

.about-us-cont-bnft h1 {
  font-size: 30px;
  font-weight: 500;
  letter-spacing: 1px;
}

.about-us-cont-bnft p {
  margin-top: 10px;
  font-size: 14px;
  list-style: none;
  line-height: 24px;
  color: #4f4f4f;
}

.about-us-img-bnft {
  width: 60vw;
  /* height: 90vh; */
}

.about-us-img-bnft img {
  object-fit: cover;
  width: 60vw;
}

#contact {
  /* width: 98.851vw; */
  /* height: 30vh; */
  background-color: #00b0cb;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.contact-1 {
  /* margin-top: 5vh; */
  flex: 50%;
  font-size: 1.5rem;
  line-height: 125.5%;
  font-weight: 600;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
}
.contact-1 h1 {
  /* margin-left: 30px; */
}
.contact-2 {
  /* margin-top: 1vh; */
  flex: 50%;
  display: flex;
  flex-direction: column;
  /* width: 40vw; */
}
.contact-2-content h2 {
  font-size: 22px;
  line-height: 125.5%;
  font-weight: 400;
  color: #fff;
  text-align: left;
  margin-bottom: 20px;
  max-width: 400px;
}

.contact-2-button {
  display: flex;
}

.contact-button {
  border-radius: 5px;
  font-size: 20px;
  background-color: #fff;
  width: 40%;
  padding: 2%;
  /* height: 50px; */
  text-transform: capitalize;
  font-weight: 600;
  color: #00b0cb;
  border: none;
  margin-right: 2.5vw;
}

@media only screen and (max-width: 800px) {
  #about-us {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
    margin-right: 20px;
  }
  .about-us-img {
    margin-top: 20px;
  }
}

@media only screen and (max-width: 600px) {
  #about-us-bnft {
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
    margin-right: 20px;
  }
}
@media only screen and (max-width: 500px) {
}
