.sections-container {
  display: flex;
  flex-direction: column;
}

.img-and-form {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  background-color: #f1fdff;
  /* flex-wrap: wrap; */
  margin-bottom: 20px;
  padding-bottom: 10px;
}
@media only screen and (max-width: 421px) {
  .img-and-form img {
    display: none;
  }
}

/* .hero-text {
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.hero-text h1 {
  font-size: 2rem;
  font-weight: 400;
  color: #000;
  line-height: 1.5;
}

.hero-text h1 strong {
  color: #00b0cb;
}

.radio-buttons {
  stroke-width: 1px;
  stroke: #a4a4a4;
  width: 1.6875rem;
  height: 1.6875rem;
  flex-shrink: 0;
}

.radio-btn-wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
}

.styled-input {
  max-width: 360px;
  width: 100%;
  height: 50px;
  border-radius: 0.625rem;
  border: 1px solid #00b0cb;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;
}

.styled-btn {
  margin-top: 2rem;
  color: white;
  width: 17rem;
  height: 4rem;
  border-radius: 0.3125rem;
  border: none;
  background: #00b0cb;
}

.hero-text span {
  font-weight: bold;
  color: #00b0cb;
  font-size: large;
  margin-bottom: 10px;
}

.styled-headline {
  color: #00b0cb;
  font-size: x-large;
  font-weight: 500;
  margin: 0;
}

label {
  margin-left: 8px;
}
.styled-card {
  border: none;
  width: 122px;
  border-radius: 6px;
  box-shadow: 0px 5px 11px 0px rgba(0, 0, 0, 0.2);
  text-align: center;
  cursor: pointer;
  height: 60px;
}

.styled-card:active {
  border: 1px solid #00b0cb;
}

.styled-sub-headline {
  font-weight: 500;
  margin-top: 10px;
  margin-bottom: 15px;
  font-size: 16px;
}

input[type="radio"] {
  width: 20px;
  height: 20px;
}

.linear-flex {
  flex: 30%;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
}

.flex-wrap-div {
  display: flex;
  flex-wrap: wrap;
}
.list-headline {
  font-size: 22px;
  font-style: normal;
  font-weight: 400;
  text-transform: capitalize;
  margin-bottom: 20px;
}
.disclaimer-content {
  color: #4f4f4f;
  font-size: 16px;
  font-weight: 400;
  text-transform: lowercase;
} */
