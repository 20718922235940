.blog-details-sec {
  flex: 65%;
  padding: 30px;
  padding-left: 60px;
  margin: 0px;
}
.recent-posts-sec {
  flex: 35%;
  padding-right: 30px;
  margin: 0px;
}
.blog-title {
  margin-top: 15px;
  font-size: 18px;
  font-weight: 500;
}
.content {
  margin-top: 20px;
}
.recent-post-txt {
  color: #4f4f4f;
  font-size: 15px;
  font-weight: 400;
  line-height: normal;
  text-transform: capitalize;
}
.recent-posts-title {
  color: #00b0cb;
  font-size: 22px;
  font-weight: 500;
  line-height: normal;
  text-transform: capitalize;
}
