.sub-header {
  background-color: #f1fdff;
  padding: 28px;
  text-align: center;
}
.headline {
  font-size: 30px;
  font-weight: 550;
  align-self: center;
}
.sub-headline {
  font-size: 16px;
  font-weight: 500;
  color: black;
}
.pos-styled-input {
  flex: 30%;
  align-self: center;
  margin: 10px;
  max-width: 360px;
  width: 100%;
  height: 50px;
  border-radius: 0.625rem;
  border: 1px solid #00b0cb;
  padding: 10px;
}
.input-container {
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.11);
  align-self: center;
  justify-content: center;
  align-items: center;
  display: flex;
  padding-left: 40px;
  padding-right: 40px;
  padding-top: 10px;
  padding-bottom: 10px;
  flex-wrap: wrap;
}
.pos-linear-div {
  justify-content: flex-start;
  width: 100%;
  display: flex;
  align-items: center;
  margin: 10px;
  margin-left: 0px;
}
.note-text {
  color: #4f4f4f;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  text-transform: lowercase;
}
.colored-txt {
  color: #00b0cb;
  font-size: 16px;
  font-weight: 600;
  line-height: 111.5%;
}
.modal-sub-headline {
  color: #4f4f4f;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 20px;
}
.btn-wrapper {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
.document-card {
  width: 186px;
  height: 185px;
  border: 2px solid #00b0cb;
  display: flex;
  text-align: center;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px;
  /* flex-wrap: wrap; */
}
.declaration-txt {
  margin-left: 10px;
  color: #4f4f4f;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}
input[type="checkbox"] {
  width: 26px;
  height: 26px;
}
.declaration {
  width: 100%;
  margin: 40px;
  display: flex;
  margin-top: 20px;
}
.document-btn {
  color: #00b0cb;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 111.5%;
  margin-top: 10px;
}

/* Also inheriting properties from .styled-btn of bike.css */
.styled-btn {
  font-weight: 500px;
  font-size: 18px;
}
