.frame-box {
  width: 100%;
  /* height: 74rem; */
  display: flex;
  margin-top: 2rem;
  padding: 1rem 3rem;
  overflow: hidden;
  justify-content: center;
}

.frame-box #circle {
  padding-top: 7rem;
  width: 550px;
  z-index: -1;
}

.frame-box #family {
  margin-top: -63rem;
  margin-left: 6rem;
  width: 450px;
  z-index: 1;
}

.frame-box .insurance-info {
  align-items: flex-start;
}

.frame-box .insurance-info h1 {
  color: #000;
  font-size: 3rem;
  font-style: normal;
  font-weight: 600;
  text-transform: capitalize;
  letter-spacing: 1px;
}

.frame-box .insurance-info h1 span {
  color: #00b0cb;
  font-size: 3rem;
  font-style: normal;
  font-weight: 700;
  text-transform: capitalize;
}

.frame-box #progress_1 {
  width: 400px;
  margin-top: 2rem;
}

.frame-box #tab1Head {
  color: #000;
  font-size: 2.8rem;
  margin-top: 3rem;
  font-style: normal;
  font-weight: 400;
  text-transform: capitalize;
}

.frame-box .radioContainer {
  display: flex;
  margin-top: 2rem;
}

.frame-box .radioContainer .radioBox {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 1rem;
}

.frame-box .radioContainer .radioBox p {
  margin-bottom: 0;
  margin-left: 1rem;
  color: #000;
  font-size: 2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 2.8125rem;
  text-transform: capitalize;
}

.frame-box .radioContainer .radioBox input {
  width: 2.1875rem;
  height: 2.1875rem;
}

.frame-box .tab1Btn {
  margin-top: 2rem;
  color: white;
  width: 17rem;
  height: 4rem;
  border-radius: 0.3125rem;
  border: none;
  background: #00b0cb;
}

.frame-box #tab2label1 {
  margin-top: 4rem;
  color: #000;
  font-size: 2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 2.8125rem;
  text-transform: uppercase;
}

.frame-box .tab3 {
  display: flex;
  flex-direction: column;
}

.frame-box input[type="text"] {
  width: 33.8125rem;
  height: 4.0625rem;
  border-radius: 0.625rem;
  border: 1px solid #00b0cb;
}

.frame-box .tab4 .tab4head {
  margin-top: 7rem;
}

.frame-box .tab4 .radioBox {
  display: flex;
  align-items: center;
  padding: 2.5rem 0.1rem;
}

.frame-box .tab4 .radioBox input {
  width: 3.6rem;
  height: 3rem;
  margin-right: 2rem;
}

.frame-box .tab4 .radioBox .opt1 {
  color: #000;
  font-size: 2rem;
  font-style: normal;
  font-weight: 400;
}

.frame-box .tab4 .radioBox .opt {
  width: 100%;
  line-height: 1.5rem;
}

.frame-box .tab4 .radioBox .opt2 {
  color: #000;
  font-size: 1.3rem;
  font-style: normal;
  font-weight: 400;
}

/* frame 2 css */

.frame2 {
  width: 98%;
  height: 70vh;
  background: #fff;
}

.frame2-heading {
  display: flex;
  text-align: center;
  height: 20%;
  width: 100%;
  justify-content: center;
}

.frame2-heading p {
  margin-top: 1%;

  text-align: center;
  color: #000;

  font-size: 2.5em;
  font-style: normal;
  font-weight: 200;

  text-transform: capitalize;
  letter-spacing: 1.5px;
}

.frame2-heading p strong {
  color: #000;

  font-style: normal;
  font-weight: 600;
  line-height: 45px;
  text-transform: capitalize;
}

.frame2-info {
  width: 100%;
  height: 80%;
  display: flex;
  position: relative;
  justify-content: space-evenly;

  flex-wrap: wrap;
}

.bnft-box-content {
  width: 25%;
  height: 90%;

  padding-left: -5%;
  margin-top: 1.5%;
}

.shadow {
  background: #fff;
  box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.11);
}

.bnft-box-content .box-content {
  padding-left: 20px;
  font-size: 17px;
  font-weight: 400;
}

.box-content1-para {
  padding-left: 1px;
  padding-right: 5px;
}

.box-img img {
  width: 40%;
  height: 40%;

  margin-top: 10%;
  margin-left: 8%;
}

.back1 {
  background-color: #eafcff;
  padding: 20px;
  border-radius: 50%;
  margin-bottom: 10px;
}

.back2 {
  background-color: antiquewhite;
  padding: 20px;
  border-radius: 50%;
  margin-bottom: 12px;
}

.back3 {
  background-color: #fbf1ff;
  padding: 20px;
  border-radius: 50%;
  margin-bottom: 10px;
}

.slot2 {
  height: 90%;
  width: 25%;
  margin: auto;
}

.slot3 {
  height: 90%;
  width: 25%;
  margin: auto;
  border: 1px solid rgb(19, 2, 57);
  margin-right: 6%;
}

/* frame 3 */

.frame3 {
  width: 98%;
  height: 90vh;
  display: flex;
  margin: auto;
  justify-content: center;
  align-items: center;
  margin-top: 5%;
}

.frame3-info {
  width: 45%;
  height: 98%;
}

.frame3-pic {
  width: 50%;
  height: 98%;
}

.frame3-info-heading {
  height: 15%;
  width: 100%;
  padding: 20px;
}

.frame3-info-heading p {
  color: #000;
  font-size: 28px;
  font-style: normal;
  font-weight: 300;

  text-transform: capitalize;
}

.frame3-info-heading p strong {
  color: #000;
  font-size: 28px;
  font-style: normal;
  font-weight: 600;

  text-transform: capitalize;
}

.frame3-list {
  height: 85%;
  width: 100%;
  padding: 20px;
}

.frame3-list ul {
  /* height: 85%; */
  width: 100%;
  margin: auto;
  /* list-style-image: url('./assets/image/listlogo.png'); */
  margin-top: -4%;
  margin-left: 5%;
}

.frame3-list ul li {
  padding-left: 2%;
  padding-right: 2%;
}

.frame3-pic img {
  margin-top: 15%;
  width: 97%;
  margin-left: 5%;
  height: 70%;
}

/* frame 4 css */

.frame4 {
  height: 110vh;
  width: 98%;
  display: flex;
  margin-top: 5%;
}

.frame4-img {
  height: 90%;
  width: 50%;
  padding: 1%;
  display: flex;
}

.frame4-info {
  height: 90%;
  width: 50%;
}

.img-back {
  position: relative;
  z-index: -1;
  height: 130%;
  width: 130%;
  border-radius: 50%;
  position: relative;
  left: -20%;
  background-color: rgb(203, 177, 142);
}

.frame4-img img {
  position: relative;
  z-index: 1;
  height: 95%;
  width: 100%;
  margin-top: 10%;
}

.frame4-info p {
  color: #000;
  padding: 10%;
  font-size: 30px;
  font-style: normal;
  font-weight: 300;
  line-height: 40px;
  text-transform: capitalize;
}

.frame4-info p strong {
  color: #000;

  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px;
  text-transform: capitalize;
}

.frame4-info ul {
  padding: 10%;
  margin-top: -15%;
  font-size: 17px;
  /* list-style-image: url('./assets/img/listlogo.png'); */
  margin-left: 4%;
}

.frame4-info ul li {
  padding-left: 4%;
  margin-top: 0;
}

/* frame 5 */

.frame5 {
  height: 40vh;
  width: 98vw;
  margin-top: 7%;
}

.frame5-heading {
  width: 100%;
  height: 30%;
}

.frame5-heading h1 {
  color: #000;

  font-size: 35px;
  font-style: normal;
  font-weight: 600;
  line-height: 45px;
  text-transform: capitalize;
  margin-left: 4.4%;
  margin-top: 2%;
  letter-spacing: 1px;
}

.frame5-para {
  width: 95%;
}

.frame5-para p {
  color: #4f4f4f;

  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  padding: 0;

  margin-left: 5%;
}
.image-and-form {
  display: flex;
  flex-wrap: wrap;
}
