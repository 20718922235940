/* hero section starts from here */
.hero-section {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  background-color: #f1fdff;
}

.hero-content {
  text-align: justify;
}

/* hero image starts from here */
.hero-img {
  width: 42vw;
  height: 70vh;
}

.hero-img img {
  width: 53.1vw;
  filter: brightness(1);
}

.hero-section::before {
  content: "";
  position: absolute;
  /* background: url('../../../../public/assets/img/Vector\ \(1\).jpg'); */
  width: 40%;
  height: 100%;
  /* z-index: -1; */
  left: 40%;
  top: 20%;
  background-repeat: no-repeat;
}

.bnft-content h1 {
  font-size: 2.2rem;
  font-weight: 400;
  text-align: center;
  letter-spacing: 2px;
  margin-bottom: 10%;
}

.bnft-box {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.bnft-box-content {
  width: 25vw;
  height: 35vh;
}

.bnft-box-content .box-content {
  font-size: 1.3rem;
  font-weight: 400;
}
.bnft-box-content .box-content p {
  letter-spacing: 1px;
}

.form-and-img {
  display: flex;
  flex-direction: row;
  /* justify-content: space-evenly; */
  justify-content: flex-end;
  background-color: #f5f5f5;
  flex-wrap: wrap;
  margin-bottom: 20px;
  padding-bottom: 10px;
}
@media only screen and (max-width: 421px) {
  .form-and-img img {
    display: none;
  }
  .form-and-img {
    justify-content: center;
  }
}
@media only screen and (max-width: 941px) {
  .form-and-img img {
    margin-left: 10px;
  }
}
