footer {
  margin: 0;
  width: 98.85vw;
  background-color: #000000;
  color: white;
}
section {
  margin: 0px;
}

.footer-section {
  position: relative;
  left: 4vw;
  width: 94%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.address-section {
  display: flex;
  border-bottom: 1px solid #5c5c5c;
  width: 100%;
  align-items: center;
  flex-wrap: wrap;
  padding-bottom: 20px;
  margin-bottom: 20px;
}
.logo-name {
  flex: 20%;
  margin: 5px;
}
.address-section .logo-name h1 {
  font-size: 30px;
  font-weight: 500;
  color: #00b0cb;
}

.content-address {
  flex: 25%;
  display: flex;
  margin: 5px;
  flex-direction: row;
  align-items: center;
}

.content-address img {
  width: 50px;
  height: 50px;
}

.contentText {
  margin-left: 5%;
}

.contentText h1 {
  font-size: 1.5rem;
  font-weight: 500;
}

.contentText h5 {
  font-size: 10px;
  font-weight: 500;
  margin-top: 0;
}

.insurance-section {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border-bottom: 1px solid #5c5c5c;
  width: 100%;
  justify-content: space-evenly;
}

.insurance-plans_investment {
  margin: 10px;
}
.insurance-plans_investment h1 {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 15px;
}

.insurance-plans_investment a {
  color: #828282;
  text-decoration: none;
  line-height: 1.5;
  font-size: 1.2rem;
  font-weight: 500;
}

/* links section starts from here */
.links-section {
  display: flex;
  border-bottom: 1px solid #5c5c5c;
  width: 100%;
  padding: 20px;
  flex-wrap: wrap;
}

.links-section a {
  text-decoration: none;
  font-size: 1.2rem;
  color: #fff;
  /* margin-right: 3%; */
}

.links-section a p {
  display: inline;
  margin: 15px;
}

/* payment links starts from here */
.payment-links-section {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #5c5c5c;
  width: 100%;
  padding: 15px;
}

.expert-cover-insurance {
  color: black;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 8vh;
  width: 100%;
}
@media only screen and (max-width: 394px) {
  .insurance-plans_investment h1 {
    font-size: 16px;
  }
}
@media only screen and (max-width: 625px) {
  .insurance-section {
    justify-content: flex-start;
  }
}
